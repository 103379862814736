.error-wrapper {
  margin-top: 120px;
}

.error-wrapper h1 {
  margin-bottom: 50px;
}

.error-wrapper h5 {
  margin: 0;
}

.error-wrapper img {
  width: 275px;
}