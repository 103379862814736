.primary-btn,
.default-btn,
.secondary-btn {
  width: 206px;
  height: 50px;
  border-radius: 25px;
  color: #ffffff;
  font-family: Celias;
  transform: translateY(0);
  transition: all 0.2s, transform 0.5s;
}

.primary-btn {
  background-color: #369696;
  box-shadow: 0px 0px 20px 0.1px rgba(54, 150, 150, 0.7);
  border: 1px solid #369696;
}

.primary-btn:disabled,
.default-btn:disabled,
.secondary-btn:disabled {
  border: 1px solid #b2b2b2;
  background-color: #b2b2b2;
  box-shadow: none;
  transform: translateY(10px);
}

.positioned-btn:disabled {
  transform: translateY(0);
}

.primary-btn:disabled:hover,
.default-btn:disabled:hover,
.secondary-btn:disabled:hover {
  border: 1px solid #b2b2b2;
  background-color: #b2b2b2;
}

.primary-btn:hover {
  background-color: #47acac;
  border-color: #47acac;
}

.primary-btn:active {
  background-color: #2a8686;
  border-color: #2a8686;
}

.primary-btn-inverted {
  border: solid 2px #369696;
  color: #369696;
  background-color: #fff;
  box-shadow: none;
}

.primary-btn-inverted:hover,
.primary-btn-inverted:active {
  color: #fff;
}

.default-btn {
  background-color: #fd5c5b;
  box-shadow: 0 2px 20px 0 rgba(253, 92, 91, 0.5);
  border: 1px solid #fd5c5b;
}

.default-btn:hover {
  background-color: #ff7372;
  border-color: #ff7372;
}

.default-btn:active {
  background-color: #ed4c4b;
  border-color: #ed4c4b;
}

.secondary-btn {
  background-color: #1f5b5b;
  box-shadow: 0 2px 20px 0 rgba(31, 91, 91, 0.5);
  border: 1px solid #369696;
}

.secondary-btn:hover {
  background-color: #306f6f;
  border-color: #306f6f;
}

.secondary-btn:active {
  background-color: #154e4e;
  border-color: #154e4e;
}

.btn-small {
  width: 120px;
}

h1 {
  font-size: 2.25em;
  line-height: 35px;
}

h2 {
  font-size: 1.75em;
  line-height: 35px;
}

h3 {
  font-size: 1.55em;
  line-height: 35px;
}

h4 {
  font-size: 1.5em;
  line-height: 32px;
}

h5 {
  font-size: 1.25em;
  line-height: 29px;
}

h6 {
  font-size: 1em;
  line-height: 25px;
}

p {
  font-size: 1em;
  line-height: 25px;
  font-family: 'Celias-light';
}

.bold {
  font-weight: bold;
}

.margin-50 {
  margin-top: 50px;
}

.margin-t20 {
  margin-top: 20px;
}

.margin-25 {
  margin-top: 25px;
}

.margin-b25 {
  margin-bottom: 25px;
}

.margin-30 {
  margin-top: 30px;
}

.margin-15 {
  margin-bottom: 15px;
}

.margin-10 {
  margin-right: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-0 {
  padding: 0 !important;
}

.margin-l20 {
  margin-left: 20px;
}

.margin-r20 {
  margin-right: 20px;
}

.margin-t0 {
  margin-top: 0;
}

.margin-b0 {
  margin-bottom: 0 !important;
}

.margin-t100 {
  margin-top: 100px;
}

.margin-t80 {
  margin-top: 60px;
}

.margin-b80 {
  margin-bottom: 80px;
}

.margin-b70 {
  margin-bottom: 70px;
}

.margin-t60 {
  margin-top: 60px;
}

.margin-b50 {
  margin-bottom: 50px;
}

.margin-0 {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.custom-textarea {
  max-width: 398px;
  width: 100%;
  height: 149px;
  border-radius: 30px;
  border: solid 2px #dbe3e8;
  text-align: center;
  font-size: 1em;
  font-family: 'Celias-light';
  padding: 10px;
  resize: none;
}

.custom-textarea:focus {
  border: solid 2px #47acac;
}

.full-width {
  width: 100%;
}

.full-max-width {
  max-width: 100%;
}

.green {
  color: #1f5b5b;
}

.sea-green {
  color: #369696;
}

.light-grey {
  color: #515151;
}

.lightest-grey {
  color: #b6b6b6;
}

.height-140 {
  height: 140px;
}

.height-175 {
  height: 175px;
}

.height-250 {
  height: 250px;
}

.height-90 {
  height: 90px;
}

.height-50 {
  height: 50px;
}

.width-115 {
  width: 115px;
}

.width-120 {
  width: 120px;
}

.width-145 {
  width: 145px;
}

.footer-margin {
  margin-bottom: 65px;
}

.line-height-1 {
  line-height: 1.2;
}

.underline:hover {
  text-decoration: underline;
}

.text-bold {
  font-family: 'Celias-bold';
  font-weight: bold;
}

.preload-images {
  display: none;
}

.red {
  color: #fd5c5b;
}

.strike {
  text-decoration: line-through;
}

.absolute {
  position: absolute;
}

.italic {
  font-style: italic;
}

@media only screen and (max-width: 769px) {
  .center-mobile {
    text-align: center;
  }

  .margin-t80,
  .margin-b80,
  .margin-b70 {
    margin-top: 0;
  }

  .margin-b50 {
    margin-bottom: 10px;
  }
}
