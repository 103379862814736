.custom-modal {
  box-shadow: none !important;
  border-radius: 4px !important;
}

.custom-modal-header {
  padding: 15px 15px 0;
}

.custom-modal-title {
  margin: 0px;
  text-align: right;
}

.custom-modal-title img {
  cursor: pointer;
}

.custom-modal-backdrop {
  background-color: #fff !important;
}

.custom-modal-body {
  padding: 0 15px 15px;
}