.home-menu {
  background: #fff;
  position: fixed;
  z-index: 1002;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding-top: 100px;
  color: #717171;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  transition: transform 0.3s ease-in-out;
  transform: translate3d(0, -125%, 0);
}

.home-menu-open {
  transform: translate3d(0, 0, 0);
}

.menu-link {
  font-size: 1em;
  height: 24px;
  line-height: 24px;
  margin-bottom: 20px;
  cursor: pointer;
}

.social-links {
  position: absolute;
  right: 30px;
  bottom: 30px;
  left: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.home-menu-separate {
  margin-top: 0;
  margin-bottom: 20px;
  border: 0;
  opacity: 0.25;
  border-top: 1px solid #979797;
}

.footer-social-links {
  margin: 0 25px;
}