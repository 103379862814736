.navbar-incola-steps {
  background-color: #fff;
  border-color: transparent;
  font-family: 'Celias';
  color: #717171;
  margin-bottom: 0;
  padding: 15px 15px 15px;
  border-bottom: 1px solid #ccc !important;
}

.navbar-link {
  padding-right: 20px;
  font-weight: bold;
}

.navbar-link a {
  font-family: 'Celias';
  font-size: 1em;
  color: #717171;
}

.nav .navbar-link a:hover,
.nav .navbar-link a:focus {
  font-family: 'Celias';
  font-weight: bold;
  outline: 0;
  background-color: transparent;
  color: #717171;
}

.navlink-selected a {
  color: #bcbcbc;
}

.navbar-header .nav-burger {
  padding: 17px 15px;
}

@media only screen and (max-width: 769px) {
  .navbar-incola {
    border-bottom: 1px solid #ccc !important;
    padding: 10px;
  }
}