.input {
  height: 50px;
  border-radius: 25px;
  border: solid 2px #dbe3e8;
  text-align: center;
  color: #565656;
  font-size: 1em;
  font-family: 'Celias-light';
  appearance: none;
}

.input:focus {
  border: solid 2px #47acac;
}

.input-error:focus {
  border: solid 2px #ff7372;
}

.input-small {
  width: 200px;
}

.input-large {
  max-width: 400px;
  width: 100%;
}

.default-input:focus {
  border: solid 2px #ff7372;
}

.primary-input {
  border: solid 2px #47acac;
}

.iconed-input {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 400px;
}

.iconed-input img {
  position: absolute;
  padding: 16px;
  width: 50px;
}

.line::after {
  content: '';
  position: absolute;
  margin-left: 46px;
  width: 1px;
  height: 48px;
  border: solid 1px #dbe3e8;
}

.line-error::after {
  border-color: #ff7372;
}

.padding-icon {
  padding-left: 46px;
}

.no-left-border {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.flat-right-border {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-error {
  border: solid 2px #ff7372;
}

@media screen and (max-width: 786px) {
  .input-3 {
    width: 33% !important;
  }

  .input-4 {
    width: 25% !important;
  }
}

.width-100 {
  width: 100%;
}

.input-2,
.input-3,
.input-4 {
  width: 133px;
}