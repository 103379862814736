.loading-wrapper{
  background: rgba(255, 255, 255, 0.7);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;  
}
.loading-circle{
  border: 5px solid #333;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  transform: rotate(0deg);
  animation: move 0.7s linear infinite;
  border-top: 5px solid #48a0a0;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: block;
}

@keyframes move{
  to{transform: rotate(360deg);}
}