@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias-Hairline.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias-HairlineItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Celias';
  src: url('./fonts/Celias-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'fontello';
  src: url('./fonts/fontello.woff2') format('woff2'),
    url('./fonts/fontello.woff') format('woff');
  font-weight: normal;
  font-style: normal !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Celias';
  color: #464646;
}

::selection {
  background-color: #1f5b5b;
  color: white;
}

:focus {
  outline: 0;
}
